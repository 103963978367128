import request from '@/utils/request'

//问题管理一览
export function problemList(data) {
  return request({
    url: "/problem/problemList",
    method: "post",
    data
  })
}

//新增问题
export function addProblem(data) {
  return request({
    url: "/problem/add",
    method: "post",
    data
  })
}

//问题 冻结、解冻
export function SetProblemFreeze(data) {
  return request({
    url: "/problem/isFreeze",
    method: "post",
    params: data
  })
}

//删除问题
export function deleteProblem(data) {
  return request({
    url: "/problem/delete",
    method: "post",
    data
  })
}

//编辑问题
export function updateProblem(data) {
  return request({
    url: "/problem/update",
    method: "post",
    data
  })
}

//问题详情
export function selectProblemById(data) {
  return request({
    url: "/problem/selectById",
    method: "post",
    params: data
  })
}
